<template>
	<div style="background-color: white;min-height: 100vh;">
		
		<van-cell-group class="from-group-item" style="margin-bottom: 10px;" v-if="info.goods_info" @click="to_detail()">
			<div class="goods-container">
				<div class="goods-wrap">
					<van-cell title-class="top-cell flex flex-row">
						<template slot="title">
			
							<van-image width="3rem" height="3rem" :src="info.goods_info.images.length > 0?info.goods_info.images[0]:''" fit="cover">
								<template v-slot:loading>
									<van-loading type="spinner" size="20" />
								</template>
							</van-image>
			
			
							<div class="text-wrap">
								<div class="name-wrap">
									<span class="text-theme1" style="font-size: 14px;">{{info.goods_info.good_name}}</span>
								</div>
								<div class="brand-wrap clamp-1">
									<span class="text-base" style="font-size: 13px;">最低成团：</span>
									<span class="text-base" style="color: #ff9b19;font-size: 13px;">{{info.goods_info.minnumber}}人成团</span>
								</div>
								<div class="price-wrap clamp-1">
									<span class="text-theme1" style="color: red;font-size: 15px;margin-right: 2px;">￥{{info.goods_info.groupbuy_price}}</span>
									<span class="text-grey">￥{{info.goods_info.alone_price}}</span>
								</div>
								
							</div>
			
						</template>
					</van-cell>
				</div>
			</div>
		</van-cell-group>
		
		<van-row type="flex" justify="center" class="from-bottom-button" v-if="info.goods_info && (info.goods_info.minnumber - info.group_info.length != 0)">
		    <van-col style="margin: 10px 0;font-size: 14px;">
				<span>还差 
				<span style="color: red;">{{info.goods_info.minnumber - info.group_info.length}}</span>
				人成团，赶紧邀请吧！</span>
			</van-col>
		</van-row>
		
		<van-row type="flex" justify="center" class="from-bottom-button" v-if="info.goods_info && (info.goods_info.minnumber - info.group_info.length == 0)">
		    <van-col style="margin: 10px 0;font-size: 14px;">
				<span>已成团</span>
			</van-col>
		</van-row>
		
		<van-row type="flex" justify="center" class="from-bottom-button" v-if="info.goods_info">
		    <van-col style="margin: 0 auto;margin-top: 10px;margin-bottom: 10px;font-size: 13px;max-width: 80%;">
				
				<!-- 邀请人员 -->
				<van-image 
				v-for="(item,index) in info.group_info" :key="index"
					width="1.2rem" 
					height="1.2rem" 
					:src="item.user.avatar" 
					fit="cover" 
					style="border: 1px solid #ff9b19;border-radius: 50%;overflow: hidden;margin-right: 10px;"/>
				
				<!-- 人员空缺 -->
				<div style="width: 1.3rem;height: 1.3rem;border: 1px dashed grey;border-radius: 50%;display: inline-block;color: grey;overflow: hidden;text-align: center;line-height: 45px;" v-show="info.goods_info.minnumber > info.group_info.length">
					<span style="">?x1</span>
				</div>
				
			</van-col>
		</van-row>
		
		<van-row type="flex" justify="center" class="from-bottom-button" v-if="info.goods_info && (info.goods_info.minnumber - info.group_info.length != 0)">
		    <van-col style="margin: 10px 0;">
				<van-button type="info" style="background-color: red;border: 0;" @click="join()">{{share==1?'立即加入':'点击右上角分享'}}</van-button>
			</van-col>
		</van-row>
		
		<van-row type="flex" justify="center" class="from-bottom-button" v-if="info && (info.goods_info.minnumber - info.group_info.length != 0) && info.group_info.length > 0">
		    <van-col style="margin: 10px 0;font-size: 13px;text-align: center;">
				<span>有效时间还剩 </span>
				<span style="color: #ff9b19;">
					<van-count-down style="color: #ff9b19;" :time="info.group_info[0].enddate" format="DD 天 HH 时 mm 分 ss 秒" />
				</span>
			</van-col>
		</van-row>
		
		<van-row type="flex" justify="center" class="from-bottom-button">
		    <van-col style="margin: 10px 0;">
				<van-button type="info" @click="to_detail()" >返回商品详情</van-button>
				<van-button type="info" @click="toactivity()" style="margin-left: 20px;">返回活动详情</van-button>
			</van-col>
		</van-row>
	</div>
</template>

<script>
import {
        Field,
        Row,
        Col,
        Cell,
        CellGroup,
        Picker,
        Popup,
        DatetimePicker,
        Uploader,
        SwitchCell,
        Checkbox,
        Button,
        Toast,
		Stepper,
		Slider,
		Collapse,
		CollapseItem,
		Loading,
		 CountDown
    } from "vant";
	import wx from "@/request/utils/wechat";
	import { activity_detail, groupbuy_user_getgoodsdetail, groupbuy_user_orderdetail, groupbuy_getgroupdetail } from '../../../../request/api.js'
export default {
	components: {
	    [Field.name]: Field,
	    [Row.name]: Row,
	    [Col.name]: Col,
	    [Cell.name]: Cell,
	    [CellGroup.name]: CellGroup,
	    [Picker.name]: Picker,
	    [Popup.name]: Popup,
	    [DatetimePicker.name]: DatetimePicker,
	    [Uploader.name]: Uploader,
	    [SwitchCell.name]: SwitchCell,
	    [Checkbox.name]: Checkbox,
	    [Button.name]: Button,
		[Stepper.name]: Stepper,
		[Slider.name]: Slider,
		[Collapse.name]: Collapse,
		[CollapseItem.name]: CollapseItem,
		[Loading.name]: Loading,
		[CountDown.name]: CountDown
	},
	data () {
		return {
			activity_id: '',
			order_id: '',
			info: {goods:{goods_name: '654'}},
			share: 0,  //用于判断是否从分享进来的
			group_id: ''
		}
	},
	mounted(){
		this.activity_id = this.$route.query.activity_id
		// this.order_id = this.$route.query.order_id
		this.group_id = this.$route.query.group_id
		if (this.$route.query.share) {
			this.share = this.$route.query.share
		}
		this.initData()
		this.loadData()
	},
	methods: {
		// 获取活动详情
		initData(){
			activity_detail({
				activity_id: this.activity_id,
				member_id: 0,
				superior: 0
			}).then(res => {
				this.is_enroll = res.data.is_enroll
			}).catch(err => {})
		},
		// 获取拼团信息
		loadData(){
			groupbuy_getgroupdetail({
				group_id: this.group_id,
			}).then(res => {
			    if (res.code == 1) {
			        this.info = res.data;
					// this.info.share_info.url = this.info.share_info.url + '&share=1'
					//初始化分享
					let shareData = {
					  title: this.info.share_info.title,
					  desc:this.info.share_info.desc,
					  img: this.info.share_info.img,
					  url: this.info.share_info.url + '&share=1'
					};
					
					wx.share(shareData);
			    } else {
			        this.$dialog({title: '提示', message: res.msg}).then(g => {
			        });
			    }
			}).catch(error => {
			    this.$dialog({title: '提示', message: "访问出错"}).then(g => {
			        this.$router.go(-1);
			    });
			});
		},
		toDetail(){
			this.$router.push({path: '/activity/marketing/group/detail', query:{id: this.info.goods_info.id, activity_id:this.activity_id}})
		},
		toactivity(){
			this.$router.push({path:'/activity',query:{id:this.activity_id}})
		},
		join(){
			if (this.share == 0) {
				return false
			}
			// 还没报名，保存路径，跳转到报名
			if (!this.is_enroll > 0) {
				this.$dialog({message: '您还未报名，请前往报名', showCancelButton: true}).then(e => {
					sessionStorage.setItem('pingtuan',this.$route.fullPath) //保存路径
					this.$router.push({ //跳转
						path: "/activity/enroll",
						query: {
							id: this.activity_id,
							back: 2
						}
					});
				}).catch(err => {
					
				})
			} else {
				// 报名就跳转到提交订单
				this.$router.push({
					path: '/activity/marketing/group/toOrder',
					query: {
						id: this.info.goods_info.id,
						activity_id: this.activity_id,
						num: 1,
						type: 1, //团购
						group_id: this.group_id
					}
				})
			}
		},
		to_detail(){
			if (this.share == 0) {
				this.$router.push({path: '/activity/marketing/group/detail', query:{id: this.info.goods_info.id, activity_id:this.activity_id}})
			} else {
				this.$router.push({path: '/activity/marketing/group/detail', query:{id: this.info.goods_info.id, activity_id:this.activity_id, group_id:this.group_id, share: 1}})
			}
		}
	}
}
</script>

<style scoped lang="less">
	// 公共商品样式
	.goods-wrap {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		background-color: white;
		font-size: 0;
		overflow: hidden;
	
		.van-image {
			margin-right: 10px;
			border-radius: 5%;
		}
	
		.text-wrap {
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
			height: 3rem;
			flex: 1;
	
			font-size: 0;
	
			span {
				font-size: 12px;
	
			}
	
			.brand-wrap {
				flex: 1;
				// height: 17px;
			}
	
			.name-wrap {
				flex: 1;
	
				// height: 17px;
				.text-base {
					// font-size: 14px;
					// font-weight: bold
				}
			}
	
			.desc-wrap {
				// height: 35px;
				flex: 2;
				margin-bottom: 5px;
			}
	
			.price-wrap {
				// height: 17px;
				flex: 1;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
	
				.text-grey {
					text-decoration: line-through;
				}
	
				.text-theme3 {
					display: block;
					font-size: 18px;
					// margin-left: 5px;
				}
			}
		}
	
	
	}
</style>
